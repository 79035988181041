<template>
  <div class="callHistoryDelete">
    <div class="callHistoryDelete__buttons">
      <b-button :disabled="!isListenedByUser(mediaUri.listenBys)" variant="danger" @click="openModalDeleteVoiceMail()">
        Supprimer le message
      </b-button>
    </div>
    <b-modal ref="modalDeleteVoiceMail" hide-header hide-footer title="Supprimer le message">
      <h2 class="text-primary text-center mb-2">
        Supprimer le message
      </h2>
      <p class="text-center">
        Le message sera supprimé dès lors que vous aurez écrit un commentaire. En supprimant ce message vocal, il ne
        sera plus disponible à aucun des autres écoutants/coordinateur : le jeune ayant laissé le message sera alors
        considéré comme ayant été pris en charge
      </p>
      <textarea v-model="comment" rows="4" type="text" class="callHistoryDelete__add-comment form-control mb-1" />
      <p v-if="commentError" class="text-danger text-center">
        Le commentaire ne peut pas être vide
      </p>
      <div class="d-flex justify-content-end">
        <b-button variant="danger" class="mr-2" @click="cancelDeleteVoiceMail()">
          Annuler
        </b-button>
        <b-button variant="primary" @click="deleteVoiceMail()">
          Valider
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import {
  BModal,
  BButton,
} from 'bootstrap-vue'
import { ref, computed } from '@vue/composition-api'

// eslint-disable-next-line import/no-cycle
import { formatDate } from '@core/utils/filter'

export default {
  name: 'HistoryAudioDelete',
  components: {
    BModal,
    BButton,
  },

  props: {
    mediaUri: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const comment = ref('')
    const commentError = ref(false)

    const userId = ref(computed(() => store.getters['users/currentUser']))

    const isListenedByUser = computed(() => listenBys => listenBys.some(listenByUser => listenByUser.userId === userId.value.sub))

    return {
      comment,
      isListenedByUser,
      commentError,
    }
  },

  methods: {
    openModalDeleteVoiceMail() {
      this.$refs.modalDeleteVoiceMail.show()
    },

    cancelDeleteVoiceMail() {
      this.$refs.modalDeleteVoiceMail.hide()
    },

    async deleteVoiceMail() {
      if (!this.comment) {
        this.commentError = true
        return
      }
      this.commentError = false
      const body = {
        comment: `Commentaire lié à la suppression du message vocal du ${formatDate(this.mediaUri.date, 'DD/MM/YYYY')}: ${this.comment}`,
        youngId: this.mediaUri.youngId,
      }

      await store.dispatch('youngs/addComment', body)
      this.$refs.modalDeleteVoiceMail.hide()
      this.comment = ''
      await store.dispatch('users/deleteCallVoicemail', this.mediaUri.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.callHistoryDelete {
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 3rem;

    @media (max-width: 1402px) {
      margin-top: 4.5rem;
    }

    @media (max-width: 1200px) {
      margin-top: 6rem;
    }
  }

  &__add-comment {
    margin-top: 1.5rem;
    resize: none;
  }
}
</style>
