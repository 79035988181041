<template>
  <div>
    <div v-if="!audioLoaded">
      <span>Chargement du média...</span>
    </div>
    <div v-if="url" class="chat-audio">
      <div>
        <audio :id="playerid" ref="player" style="display:none" @play="isPlaying = true">
          <source :src="url" type="audio/mpeg">
        </audio>
      </div>

      <div class="chat-audio-player">
        <div class="d-flex flex-wrap">
          <div class="flex-initial d-flex justify-content-center align-items-center">
            <svg v-show="!isPlaying" class="chat-audio-player-button" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 20 20" fill="currentColor" @click="toggleAudio()">
              <path fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clip-rule="evenodd" />
            </svg>
            <svg v-show="isPlaying" class="chat-audio-player-button text-orange-400 hover:text-orange-400 cursor-pointer"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" @click="toggleAudio()">
              <path fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                    clip-rule="evenodd" />
            </svg>
          </div>

          <div class="chat-audio-progress-bar position-relative flex-grow-1 border">
            <input id="position" v-model="playbackTime" type="range" min="0" :max="audioDuration"
                   class="test-miam slider w-100 h-100" name="position">

            <div
              class="d-flex justify-content-center align-items-center position-absolute w-100 h-100 chat-audio-player-elapsed-time-overlay">
              <span v-html="elapsedTime()"> 00:00 </span>
              <span>/</span>
              <span v-html="totalTime()"> 00:00 </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  props: {
    playerid: {
      required: true,
      type: Number,
    },
    url: {
      default: '',
      type: String,
    },
  },
  /**
   * audioDuration = duration of audio file in seconds
   * isPlaying = boolean (true if audio is playing)
   * playbackTime = local var that syncs to audio.currentTime
   *
   * */
  setup() {
    const audioDuration = ref(100)
    const audioLoaded = ref(false)
    const isPlaying = ref(false)
    const playbackTime = ref(0)

    // Set the range slider max value equal to audio duration
    function initSlider() {
      const audio = this.$refs.player
      if (audio) {
        this.audioDuration = Math.round(audio.duration)
      }
    }
    // Convert audio current time from seconds to min:sec display
    function convertTime(seconds) {
      const format = val => `0${Math.floor(val)}`.slice(-2)
      const minutes = (seconds % 3600) / 60
      return [minutes, seconds % 60].map(format).join(':')
    }

    // Show the total duration of audio file
    function totalTime() {
      const audio = this.$refs.player
      if (audio) {
        const seconds = audio.duration
        return this.convertTime(seconds)
      }
      return '00:00'
    }
    // Display the audio time elapsed so far
    function elapsedTime() {
      const audio = this.$refs.player
      if (audio) {
        const seconds = audio.currentTime
        return this.convertTime(seconds)
      }
      return '00:00'
    }
    // Playback listener function runs every 100ms while audio is playing
    function playbackListener() {
      const audio = this.$refs.player
      // Sync local 'playbackTime' var to audio.currentTime and update global state
      this.playbackTime = audio.currentTime

      // Add listeners for audio pause and audio end events
      audio.addEventListener('ended', this.endListener)
      audio.addEventListener('pause', this.endListener)
    }
    // Function to run when audio play reaches the end of file
    function endListener() {
      this.isPlaying = false
      this.listenerActive = false
      this.cleanupListeners()
    }
    // Remove listeners after audio play stops
    function cleanupListeners() {
      const audio = this.$refs.player
      audio.removeEventListener('timeupdate', this.playbackListener)
      audio.removeEventListener('ended', this.endListener)
      audio.removeEventListener('pause', this.endListener)
    }
    function toggleAudio() {
      const audio = this.$refs.player
      // var audio = document.getElementById('audio-player')
      if (audio.paused) {
        audio.play()
        this.isPlaying = true
      } else {
        audio.pause()
        this.isPlaying = false
      }
    }
    function onTick() {
      const audio = this.$refs.player
      // Wait for audio to load, then run initSlider() to get audio duration and set the max value of our slider
      if (!audio) { return }
      audio.addEventListener(
        'loadedmetadata',
        () => {
          this.initSlider()
        },
      )
      audio.addEventListener(
        'canplay',
        () => {
          this.audioLoaded = true
        },
      )
      // Wait for audio to begin play, then start playback listener function
      this.$watch('isPlaying', () => {
        if (this.isPlaying) {
          const audio2 = this.$refs.player
          this.initSlider()
          //  prevent starting multiple listeners at the same time
          if (!this.listenerActive) {
            this.listenerActive = true
            // for a more consistent timeupdate, include freqtimeupdate.js and replace both instances of 'timeupdate' with 'freqtimeupdate'
            audio2.addEventListener('timeupdate', this.playbackListener)
          }
        }
      })
      // Update current audio position when user drags progress slider
      this.$watch('playbackTime', () => {
        const audio2 = this.$refs.player
        const diff = Math.abs(this.playbackTime - this.$refs.player.currentTime)

        // Throttle synchronization to prevent infinite loop between playback listener and this watcher
        if (diff > 0.01) {
          audio2.currentTime = this.playbackTime
        }
      })
    }

    return {
      audioDuration,
      audioLoaded,
      cleanupListeners,
      convertTime,
      elapsedTime,
      endListener,
      initSlider,
      isPlaying,
      onTick,
      playbackTime,
      playbackListener,
      toggleAudio,
      totalTime,
    }
  },
  watch: {
    url() {
      if (this.url) {
        this.$nextTick(this.onTick)
        this.listeners()
      }
    },
  },
  mounted() {
    this.$nextTick(this.onTick)
  },
  methods: {
    listeners() {
      const audio = this.$refs.player
      if (!audio) { return }
      audio.addEventListener(
        'loadedmetadata',
        () => {
          console.log('loaded')
          this.initSlider()
        },
      )
      audio.addEventListener(
        'canplay',
        () => {
          console.log('canplay')
          this.audioLoaded = true
        },
      )
    },
  },
}
</script>

<style lang="scss" >
.callHistory__audio-bold  {
  input[type="range"] {
    accent-color: orange;
    background: orange;
  }
}

</style>
