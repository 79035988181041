<template>
  <div>
    <b-tabs content-class="mt-3">
      <b-tab title="Enregistrement Répondeur" active>
        <div class="callHistory">
          <b-card id="callHistoryCard" class="callHistory__card">
            <b-table class="position-relative" :fields="fields" :items="appels">
              <template #cell(date)="data">
                <div>
                  {{ formatDate(data.item.date) }}
                </div>
              </template>
              <template #cell(enregistrement)="mediaUri">
                <div class="callHistory__audio-div" :class="{ 'callHistory__audio-bold': isUnread(mediaUri.item) }">
                  <div @click="sendListen(mediaUri.item.id, mediaUri.item.listenBys)">
                    <HistoryAudioPlayer :media-uri="mediaUri.item.mediaUri" :media-id="mediaUri.item.id" />
                  </div>
                  <p v-if="isUnread(mediaUri.item)" class="callHistory__unread-text">
                    Non lu par vous
                  </p>
                  <div v-if="!mediaUri.item.liste" class="callHistory__initials"
                    @click.prevent="openModalListenBys(mediaUri.item.listenBys)">
                    <initials-avatar v-for="listenBy in mediaUri.item.listenBys.slice(0, 3)" :key="listenBy.userId"
                      size="26" :family-name="listenBy.userLastName" :given-name="listenBy.userFirstName"
                      :show-title="true" />
                  </div>
                  <HistoryAudioDelete :media-uri="mediaUri.item" />
                </div>
              </template>
            </b-table>
          </b-card>
        </div>
      </b-tab>
      <b-tab v-if="showRecordedCalls" title="Enregistrement Appels">
        <div class="callRecording">
          <b-card id="callRecordingCard" class="callRecording__card">
            <b-table class="position-relative" :fields="fields" :items="callRecording">
              <template #cell(date)="data">
                <div>
                  {{ formatDate(data.item.date) }}
                </div>
              </template>
              <template #cell(enregistrement)="mediaUri">
                <div v-if="mediaUri.item.mediaUri">
                  <HistoryAudioPlayer :media-uri="mediaUri.item.mediaUri" :media-id="mediaUri.item.id" />
                </div>
              </template>
            </b-table>
          </b-card>
        </div>
      </b-tab>
    </b-tabs>
    <b-modal ref="modalListenBys" hide-header hide-footer title="Personnes ayant écouté le message">
      <h2 class="text-primary text-center mb-2">
        Personnes ayant écouté le message
      </h2>
      <p v-for="listener in listeners" :key="listener.userId" class="text-center">
        {{ listener.userFirstName }} {{ listener.userLastName }} le {{ formatDate(listener.date) }}
      </p>
      <div class="d-flex justify-content-end">
        <b-button variant="primary" class="mr-2" @click="closeModalListenBys()">
          Fermer
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import {
  BCard,
  BTable,
  BTab,
  BTabs,
  BModal,
  BButton,
} from 'bootstrap-vue'
import { ref, onMounted, computed } from '@vue/composition-api'
import HistoryAudioPlayer from '@/components/HistoryAudioPlayer.vue'
import HistoryAudioDelete from '@/components/HistoryAudioDelete.vue'
import InitialsAvatar from '@/components/InitialsAvatar.vue'

// eslint-disable-next-line import/no-cycle
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BTable,
    BTab,
    BTabs,
    BModal,
    BButton,
    HistoryAudioPlayer,
    InitialsAvatar,
    HistoryAudioDelete,
  },

  setup() {
    const SHOW_RECORDED_CALLS = false
    const nextItem = ref(1)
    const loading = ref(false)
    const pageSize = ref(7)
    const pageNumber = ref(1)
    const listeners = ref([])

    const userId = ref(computed(() => store.getters['users/currentUser']))

    const appels = ref(computed(() => store.getters['users/getHistoryCall'].map(obj => {
      const {
        youngLastName: nom,
        youngFirstName: prenom,
        youngPhoneNumber: telephone,
        ...rest
      } = obj

      return {
        nom,
        prenom,
        telephone,
        ...rest,
      }
    })))

    const callRecording = SHOW_RECORDED_CALLS ? ref(computed(() => store.getters['users/getCallRecording'].map(obj => {
      const {
        youngLastName: nom,
        youngFirstName: prenom,
        youngPhoneNumber: telephone,
        ...rest
      } = obj

      return {
        nom,
        prenom,
        telephone,
        ...rest,
      }
    }))) : []

    const isUnread = mediaUri => mediaUri.listenBys.every(listenBy => listenBy.userId !== userId.value.sub)

    const sendListen = async (mediaId, listenBys) => {
      if (listenBys.some(listenByUser => listenByUser.userId === userId.value.sub)) {
        return
      }
      const body = {
        callId: mediaId,
        date: new Date().toISOString(),
        userId: userId.value.sub,
        userFirstName: userId.value.given_name,
        userLastName: userId.value.family_name,
      }
      await store.dispatch('users/updateListenBy', body)
    }

    const loadMore = () => {
      loading.value = true
      store.dispatch('users/fetchHistoryCall', {
        PageNumber: pageNumber.value,
        PageSize: pageSize.value,
      })

      pageNumber.value += 1
    }

    onMounted(() => {
      if (SHOW_RECORDED_CALLS) {
        store.dispatch('users/fetchCallRecording')
      }

      store.dispatch('users/fetchHistoryCall', {
        PageNumber: pageNumber.value,
        PageSize: pageSize.value,
      })

      pageNumber.value += 1

      const listElm = document.querySelector('#callHistoryCard')
      listElm.addEventListener('scroll', () => {
        if (listElm.scrollTop + listElm.clientHeight + 1 >= listElm.scrollHeight) {
          loadMore()
        }
      })
    })

    const fields = [
      { key: 'date', sortable: true },
      { key: 'telephone', sortable: false },
      { key: 'nom', sortable: true },
      { key: 'prenom', sortable: true },
      { key: 'enregistrement', sortable: true },
    ]

    return {
      appels,
      fields,
      callRecording,
      loadMore,
      nextItem,
      loading,
      formatDate,
      sendListen,
      userId,
      listeners,
      isUnread,
    }
  },
  data() {
    return {
      showRecordedCalls: false, // this was added as a non permanent change. This may be removed when the client ask to make this functionnality available again.
    }
  },
  methods: {
    openModalListenBys(listeners) {
      this.listeners = listeners
      this.$refs.modalListenBys.show()
    },
    closeModalListenBys() {
      this.$refs.modalListenBys.hide()
      this.listeners = []
    },
  },
}
</script>

<style lang="scss">
.callHistory {
  &__card {
    overflow: auto;
    height: 60vh;
  }

  .card-body {
    padding: 0;
  }

  thead {
    z-index: 1;
    position: sticky;
    top: 0;
  }

  th,
  td {
    padding: 2rem 2rem;
    border-top-width: 0px;
  }

  svg {
    min-width: 25px;
  }

  &__audio-bold {
    font-weight: bold;
  }

  &__unread-text {
    margin-top: 6px;
    padding-left: 2rem;
    position: absolute;

    @media (max-width: 1402px) {
      margin-top: 2rem;
    }

    @media (max-width: 1200px) {
      padding-left: 0;
    }
  }

  &__initials {
    margin-top: 6px;
    right: 2rem;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 4px;

    @media (max-width: 1402px) {
      margin-top: 2rem;
    }

    @media (max-width: 1200px) {
      margin-top: 3.5rem;
    }
  }

  &__initials:hover {
    cursor: pointer;
  }
}

.callRecording {
  &__card {
    overflow: auto;
  }

  .card-body {
    padding: 0;
  }

  thead {
    z-index: 1;
    position: sticky;
    top: 0;
  }

  th,
  td {
    padding: 2rem 2rem;
    border-top-width: 0px;
  }

  svg {
    min-width: 25px;
  }
}
</style>
