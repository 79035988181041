<template>
  <ChatAudioPlayer :url="src" :playerid="mediaId" />
</template>

<script>
import ChatAudioPlayer from '@/components/ChatAudioPlayer.vue'
import axiosIns from '@/libs/axiosIns'

export default {
  components: {
    ChatAudioPlayer,
  },
  props: {
    mediaUri: {
      default: '',
      type: String,
    },
    mediaId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      src: '',
    }
  },
  beforeMount() {
    this.getMediaUri()
  },
  methods: {
    async getMediaUri() {
      try {
        const data = await axiosIns.get(this.mediaUri, {
          responseType: 'arraybuffer',
        })
        const contentType = data.headers['content-type']
        const mediab64 = Buffer.from(data.data, 'binary').toString('base64')
        this.src = `data:${contentType};base64, ${mediab64}`
      } catch (error) {
        this.src = this.mediaUri
      }
    },
  },
}
</script>
